<template>
  <div class="login" v-if="!isProcessing">
    <v-btn class="login__btn" @click="signin()">ログイン</v-btn>
  </div>
</template>

<script>
export default {
  name: 'login',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * ログイン
     */
    signin () {
      this.$store.commit('setSubmitting', true)

      this.$store.dispatch('auth/signin', 'google')
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.login {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
  &__btn {
    display: block;
    margin: 0 auto;
  }
}
</style>
